<template>
  <div class="contact_form">
    <h4>Get in Touch</h4>
    <div class="contact_form_grid">
      <form @submit.prevent="submitForm">
        <div class="form-group">
          <input
            type="text"
            @input="validateForm"
            v-model="subject"
            placeholder="Subject"
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            @input="validateForm"
            v-model="fullName"
            placeholder="Name"
          />
        </div>
        <div class="form-group">
          <input
            type="email"
            @input="validateForm"
            v-model.trim="email"
            placeholder="Email"
          />
        </div>
        <div class="form-group">
          <textarea
            cols="30"
            @input="validateForm"
            v-model="description"
            rows="10"
          ></textarea>
        </div>
        <div class="form-group text-center">
          <transition name="fade">
            <p
              v-if="formMessage"
              style="
                color: #a80024 !important;
                font-size: 16px !important;
                font-weight: 900 !important;
                margin-top: 28px;
                margin-bottom: 20px;
                text-align: center !important;
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 20 20"
                svg-inline=""
                role="presentation"
                focusable="false"
                tabindex="-1"
                style="color: #a80024 !important"
                fill="#a80024"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm0 11c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1 4H9v-2h2v2z"
                ></path>
              </svg>
              {{ formMessage }}
            </p>
          </transition>
        </div>
        <div class="form-group text-center">
          <button :disabled="emptyFields" class="relsify-button">
            <span v-if="!loading">Submit </span>
            <span class="loader" v-if="loading"></span>
          </button>
        </div>
      </form>
      <div class="contact_form_grid_info">
        <div class="contact_form_grid_info_email">
          <h5>Email:</h5>
          <p>Email : info@relsify.com</p>
          <p>support@relsify.com</p>
        </div>
        <div class="contact_form_grid_info_Icons">
          <h5>Stay connected:</h5>
          <div class="contact_form_grid_info_Icons_socials">
            <a href="http://instagram.com/relsify" target="_blank"
              ><i class="bx bxl-instagram"></i
            ></a>
            <a href="http://facebook.com/officialrelsify" target="_blank"
              ><i class="bx bxl-facebook"></i
            ></a>
            <a href="http://twitter.com/_relsify" target="_blank"
              ><i class="bx bxl-twitter"></i
            ></a>
            <a href="http://t.me/relsify_official" target="_blank"
              ><i class="bx bxl-telegram"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      subject: "",
      fullName: "",
      email: "",
      description: "",
      formMessage: "",
      emptyFields: true,
      loading: false,
    };
  },

  methods: {
    ...mapActions(["setAlertpopUp"]),
    validateForm: function () {
      if (!this.subject || !this.fullName || !this.email || !this.description) {
        this.emptyFields = true;
        this.formMessage = "Fill out all Fields";
        return false;
      }

      if (!this.validEmail(this.email)) {
        this.emptyFields = true;
        this.formMessage = "please input a valid email address";
        return false;
      }

      this.emptyFields = false;
      this.formMessage = "";
      return true;
    },

    submitForm: function () {
      this.loading = true;
      let url = `${this.RELSIFY_URL}/api/contact-form`;
      var requestPayload = {
        email: this.email,
        subject: this.subject,
        name: this.fullName,
        message: this.description,
      };
      this.axios
        .post(url, requestPayload)
        .then((data) => {
          setTimeout(() => {
            this.loading = false;
            if (!data.data.error) {
              console.log(data.data);
              this.setAlertpopUp({
                status: true,
                title: "Message Sent!",
                des: data.data.meta.message,
                type: "success",
                payload: null,
              });
              this.clearFormFields();
            } else {
              this.setAlertpopUp({
                status: true,
                title: "Message Not Sent!",
                des: data.data.meta.message,
                type: "error",
                payload: null,
              });
            }
          }, 3000);
        })
        .catch(() => {
          this.loading = false;
          this.setAlertpopUp({
            status: true,
            title: "Message Not Sent!",
            des: "An Error occurred!",
            type: "error",
            payload: null,
          });
        });
    },

    clearFormFields: function () {
      this.subject = "";
      this.fullName = "";
      this.email = "";
      this.description = "";
      this.formMessage = "";
      this.emptyFields = true;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
[class*="loader"]:before {
  content: "Please wait...";
}
.contact_form {
  padding: 80px 120px;
  background: var(--mainGreenColor);
}

.contact_form h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 67px;
  color: #ffffff;
  margin-bottom: 80px;
}

.contact_form_grid {
  display: grid;
  grid-template-columns: 1fr 400px;
  /* column-gap: 100px; */
}

.contact_form_grid form {
  max-width: 80%;
  width: 100%;
  margin-right: 60px;
}

.contact_form_grid form .form-group {
  width: 100%;
  margin-bottom: 20px;
}

.contact_form_grid form .form-group input,
.contact_form_grid form .form-group textarea {
  width: 100%;
  padding: 13px 32px;
  border-radius: 2px;
  border: none;
  transition: all 300ms ease-in;
}

.contact_form_grid form .form-group input:focus,
.contact_form_grid form .form-group textarea:focus {
  outline: none;
  padding-left: 19px;
}

.contact_form_grid form .form-group button {
  padding: 18px 102px;
  font-size: 16px;
  margin-top: 30px;
}

.contact_form_grid_info {
  color: #ffffff;
  text-align: left;
}

.contact_form_grid_info h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 3px;
}

.contact_form_grid_info p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 0px;
}

.contact_form_grid_info_Icons {
  margin-top: 40px;
}
.contact_form_grid_info_Icons_socials {
  display: flex;
  margin-top: 20px;
}

.contact_form_grid_info_Icons a {
  color: #fff;
  margin: 0px 10px;
}

.contact_form_grid_info_Icons a i {
  font-size: 22px;
  padding: 15px;
  border: 2px solid #ffffff;
  border-radius: 100%;
  font-weight: bold;
  transition: all 400ms ease-in-out;
}

.contact_form_grid_info_Icons a i:hover {
  transform: scale(1.2);
}

@media screen and (max-width: 1400px) {
  .contact_form {
    padding: 80px 100px;
  }

  .contact_form_grid {
    grid-template-columns: 1fr 400px;
  }

  .contact_form_grid form {
    max-width: 80%;
    margin-right: 60px;
  }
}

@media screen and (max-width: 1150px) {
  .contact_form {
    padding: 80px;
  }

  .contact_form_grid {
    grid-template-columns: 1fr 300px;
  }

  .contact_form h4 {
    font-size: 39px;
    margin-bottom: 80px;
  }

  .contact_form_grid form {
    max-width: 80%;
    margin-right: 40px;
  }

  .contact_form_grid_info_Icons a i {
    font-size: 19px;
    padding: 10px;
  }

  .contact_form_grid form .form-group button {
    padding: 18px 82px;
    font-size: 15px;
  }
}

@media screen and (max-width: 1050px) {
  .contact_form {
    padding: 60px;
  }

  .contact_form h4 {
    font-size: 39px;
    text-align: center;
    margin-bottom: 50px;
  }

  .contact_form_grid {
    display: block;
  }

  .contact_form_grid form {
    max-width: 70%;
    margin: 0px auto 50px;
  }

  .contact_form_grid_info {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 80px;
  }

  .contact_form_grid_info_Icons {
    margin-top: 0px;
  }
  .contact_form_grid_info_Icons_socials {
    margin-top: 20px;
  }
}

@media screen and (max-width: 900px) {
  .contact_form_grid form {
    max-width: 100%;
    margin: 0px auto 50px;
  }
}

@media screen and (max-width: 750px) {
  .contact_form_grid_info {
    display: block;
    margin-top: 80px;
  }

  .contact_form_grid_info_Icons {
    margin-top: 30px;
  }
}

@media screen and (max-width: 600px) {
  .contact_form {
    padding: 40px 20px;
  }

  .contact_form_grid_info h5 {
    font-size: 19px;
  }
}

@media screen and (max-width: 500px) {
  .contact_form {
    padding: 30px 15px 50px;
  }

  .contact_form h4 {
    font-size: 25px;
    margin-bottom: 40px;
  }

  .contact_form_grid_info h5 {
    font-size: 19px;
  }

  .contact_form_grid form .form-group button {
    padding: 10px 0px;
    width: 100%;
    font-size: 15px;
    margin-top: 20px;
  }

  .contact_form_grid_info {
    margin-top: 70px;
  }

  .contact_form_grid_info_Icons a i {
    font-size: 15px;
    padding: 10px;
  }
}
</style>
