<template>
  <div class="tokenize-journey">
    <div style="margin-bottom: 100px">
      <h4 ref="tokenize_journey_explore_header"
      :class="{
        'slide-in-right': is_tokenize_journey_explore_header_visible
      }"
      >Explore with <span class="text-green">Tokenization</span></h4>
      <div ref="tokenize_journey_explore_content"
      :class="{
        'slide-in-left': is_tokenize_journey_explore_content_visible
      }">
        <p>
          The first question that comes to mind is what tokenization? Why should I
          tokenize my projects? Let's go straight to the point.
        </p>
        <p>
          Tokenization is when a physical real estate project is represented with
          digital tokens on the blockchain such that it becomes fractionalized.
        </p>
        <p>
          Suppose you want to tokenize a 100,000sqm property that is worth $10m.
          Tokenizing is dividing the property into smaller bits such that you
          offer 1 share for every sqm.
        </p>
        <p>
          That means the property is divided into 100,000 shares (tokens), each
          representing 1sqm of your 100,000sqm property and offered at $100 each.
        </p>
        <p>
          The total value of all tokens is exactly equivalent to the total value
          of the tokenized property. In the above case, $100 multiplied by 100,000
          shares gives $10m, the actual value of the property.
        </p>
        <p>
          Models can be tweaked such that minimum share entry for an Investor
          could be specified or the property is further divided until $50 or less
          is the minimum entry to allow for more retail investors.
        </p>
        <p>
          Whatever percentage returns generated from the property is paid as
          dividends among token holders based on their investment equivalent and
          pre-stated timeframes.
        </p>
      </div>
      <div class="token_button">
        <button ref="tokenize_journey_explore_button" 
        :class="{
          'zoom-in': is_tokenize_journey_explore_button_visible
        }" @click="$router.push({ name: 'TokenizePropertyForm' })">
          Start Your Journey
        </button>
      </div>
    </div>
    <div style="margin-bottom: 100px">
      <h4 ref="tokenize_journey_advantage_header" :class="{
        'slide-in-left': is_tokenize_journey_advantage_header_visible
      }">Your Competitive <span class="text-green">Advantage</span></h4>
      <div ref="tokenize_journey_advantage_content" :class="{
        'slide-in-right': is_tokenize_journey_advantage_content_visible
      }">
        <p>
          In a pool of Real Estate Developers, the problems faced aren't
          far-fetched from one another.
        </p>
        <p>
          <ul>
            <li>Slow turnover</li>
            <li>Inaccessible loan schemes</li>
            <li>Inflation that hurts delivery price</li>
            <li>And much more</li>
          </ul>
          <!-- - Slow turnover <br />
          - Inaccessible loan schemes <br />
          - Inflation that hurts delivery price <br />
          - And much more <br /> -->
        </p>
        <p>Your competitive advantage comes in when you could:</p>
        <p>
          <ul>
            <li>Attract smaller investors</li>
            <li>Attract international buyers</li>
            <li>Deliver projects faster and hedge inflation</li>
            <li>Service low income and middle-class earners</li>
            <li>Digitize, minimize cost, automate processes, and make better offers</li>
          </ul>
          <!-- - Attract smaller investors <br />
          - Attract international buyers <br />
          - Deliver projects faster and hedge inflation <br />
          - Service low income and middle-class earners <br />
          - Digitize, minimize cost, automate processes, and make better offers
          <br /> -->
        </p>
        <p>
          Evolve from Land Banking, Buy Back Plans, and all sorts of
          cash-generating strategies promising way too high returns and positioned
          in front of a limited or cash-strapped audience.
        </p>
        <p>
          Begin to offer something more comfortable and reach a willing global
          audience seamlessly!
        </p>
        <p>
          Tap into a customer base that has been ignored for several decades and
          another that has been emerging for more than 10years!
        </p>
        <p>
          Remember, you don't have to do anything wrong to get kicked out of the
          market, you just have to maintain the status quo and watch your
          competitors make moves.
        </p>
      </div>
      <div class="token_button">
        <button ref="tokenize_journey_advantage_button" 
        :class="{
          'zoom-in': is_tokenize_journey_advantage_button_visible
        }" @click="$router.push({ name: 'TokenizePropertyForm' })">
          Start Your Journey
        </button>
      </div>
    </div>
    <div style="margin-bottom: 100px">
      <h4 ref="tokenize_journey_experience_header" :class="{
        'slide-in-right': is_tokenize_journey_experience_header_visible
      }">Your <span class="text-green">Experience</span>, Your  <span class="text-green"> Gain</span></h4>
      <div ref="tokenize_journey_experience_content" :class="{
        'slide-in-left': is_tokenize_journey_experience_content_visible
      }">
        <p>
          The more experienced you are in the Real Estate industry, the more you could penetrate other untapped climes in the industry via tokenization. .
        </p>
        <p>That means, you could tokenize across:</p>
        <p>
          <ul>
            <li>
              Residential Projects <br>
              Block of flats, Bungalows, Duplexes, Terraces, High Rise, etc. 

            </li>
            <li>
              Commercial Projects <br>
              Short lets, Office
  Space, Hotels, Warehouse, Malls,
  Car Parks etc.
            </li>
            <li>
              Industrial Projects<br>
              Filling stations, gas plants etc.
            </li>
            <li>
              Agricultural Projects <br>
              Cash crops, grain, plantation, etc.
            </li>
          
          </ul>
          <!-- - Slow turnover <br />
          - Inaccessible loan schemes <br />
          - Inflation that hurts delivery price <br />
          - And much more <br /> -->
        </p>

        <p>Build everything you ever desired to build!</p>
      </div>
     
      <div class="token_button">
        <button ref="tokenize_journey_experience_button" 
        :class="{
          'zoom-in': is_tokenize_journey_experience_button_visible
        }" @click="$router.push({ name: 'TokenizePropertyForm' })">
          Start Your Journey
        </button>
      </div>
    </div>
    <div style="margin-bottom: 100px">
      <h4 ref="tokenize_journey_blockchain_header" :class="{
        'slide-in-right': is_tokenize_journey_blockchain_header_visible
      }">Blockchain To The <span class="text-green">Rescue</span></h4>
      <div ref="tokenize_journey_blockchain_content" :class="{
        'slide-in-left': is_tokenize_journey_blockchain_content_visible
      }">
        <p>
          Our technological infrastructure allows you as a Developer drive to
          effectiveness your cash generating strategy such that it reaches global
          level and offer willing investors an opportunity for fractional
          ownership based on pre-stated terms and conditions documented and
          automatically executed by a smart contract.
        </p>
        <p>
          With the rapid advancement of technology, strategic digitization remains
          the singular most essential component for real estate advancement in
          Africa and for the unlocking of untapped industry potentials.
        </p>
        <p>
          Thanks to Technology, Thanks to Blockchain & Thanks to Tokenization.
        </p>
        <p>Ready for a quantum leap?</p>
      </div>
      <div class="token_button">
        <button ref="tokenize_journey_blockchain_button" 
        :class="{
          'zoom-in': is_tokenize_journey_blockchain_button_visible
        }" @click="$router.push({ name: 'TokenizePropertyForm' })">
          Start Your Journey
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapActions, mapGetters } from "vuex";
export default {
  setup() {
    const tokenize_journey_explore_header = ref(null)
    const is_tokenize_journey_explore_header_visible = ref(false)
    const tokenize_journey_explore_header_observer = new IntersectionObserver(([entry]) => {
      is_tokenize_journey_explore_header_visible.value  = entry.isIntersecting
    })

    const tokenize_journey_explore_content = ref(null)
    const is_tokenize_journey_explore_content_visible = ref(false)
    const tokenize_journey_explore_content_observer = new IntersectionObserver(([entry]) => {
      is_tokenize_journey_explore_content_visible.value  = entry.isIntersecting
    })

    const tokenize_journey_explore_button = ref(null)
    const is_tokenize_journey_explore_button_visible = ref(false)
    const tokenize_journey_explore_button_observer = new IntersectionObserver(([entry]) => {
      is_tokenize_journey_explore_button_visible.value  = entry.isIntersecting
    })


    const tokenize_journey_advantage_header = ref(null)
    const is_tokenize_journey_advantage_header_visible = ref(false)
    const tokenize_journey_advantage_header_observer = new IntersectionObserver(([entry]) => {
      is_tokenize_journey_advantage_header_visible.value  = entry.isIntersecting
    })

    const tokenize_journey_advantage_content = ref(null)
    const is_tokenize_journey_advantage_content_visible = ref(false)
    const tokenize_journey_advantage_content_observer = new IntersectionObserver(([entry]) => {
      is_tokenize_journey_advantage_content_visible.value  = entry.isIntersecting
    })

    const tokenize_journey_advantage_button = ref(null)
    const is_tokenize_journey_advantage_button_visible = ref(false)
    const tokenize_journey_advantage_button_observer = new IntersectionObserver(([entry]) => {
      is_tokenize_journey_advantage_button_visible.value  = entry.isIntersecting
    })

    const tokenize_journey_experience_header = ref(null)
    const is_tokenize_journey_experience_header_visible = ref(false)
    const tokenize_journey_experience_header_observer = new IntersectionObserver(([entry]) => {
      is_tokenize_journey_experience_header_visible.value  = entry.isIntersecting
    })

    const tokenize_journey_experience_content = ref(null)
    const is_tokenize_journey_experience_content_visible = ref(false)
    const tokenize_journey_experience_content_observer = new IntersectionObserver(([entry]) => {
      is_tokenize_journey_experience_content_visible.value  = entry.isIntersecting
    })

    const tokenize_journey_experience_button = ref(null)
    const is_tokenize_journey_experience_button_visible = ref(false)
    const tokenize_journey_experience_button_observer = new IntersectionObserver(([entry]) => {
      is_tokenize_journey_experience_button_visible.value  = entry.isIntersecting
    })

    const tokenize_journey_blockchain_header = ref(null)
    const is_tokenize_journey_blockchain_header_visible = ref(false)
    const tokenize_journey_blockchain_header_observer = new IntersectionObserver(([entry]) => {
      is_tokenize_journey_blockchain_header_visible.value  = entry.isIntersecting
    })

    const tokenize_journey_blockchain_content = ref(null)
    const is_tokenize_journey_blockchain_content_visible = ref(false)
    const tokenize_journey_blockchain_content_observer = new IntersectionObserver(([entry]) => {
      is_tokenize_journey_blockchain_content_visible.value  = entry.isIntersecting
    })

    const tokenize_journey_blockchain_button = ref(null)
    const is_tokenize_journey_blockchain_button_visible = ref(false)
    const tokenize_journey_blockchain_button_observer = new IntersectionObserver(([entry]) => {
      is_tokenize_journey_blockchain_button_visible.value  = entry.isIntersecting
    })

    return {
      tokenize_journey_explore_header,
      is_tokenize_journey_explore_header_visible,
      tokenize_journey_explore_header_observer,

      tokenize_journey_explore_content,
      is_tokenize_journey_explore_content_visible,
      tokenize_journey_explore_content_observer,

      tokenize_journey_explore_button,
      is_tokenize_journey_explore_button_visible,
      tokenize_journey_explore_button_observer,

      tokenize_journey_advantage_header,
      is_tokenize_journey_advantage_header_visible,
      tokenize_journey_advantage_header_observer,

      tokenize_journey_advantage_content,
      is_tokenize_journey_advantage_content_visible,
      tokenize_journey_advantage_content_observer,

      tokenize_journey_advantage_button,
      is_tokenize_journey_advantage_button_visible,
      tokenize_journey_advantage_button_observer,

      tokenize_journey_experience_header,
      is_tokenize_journey_experience_header_visible,
      tokenize_journey_experience_header_observer,

      tokenize_journey_experience_content,
      is_tokenize_journey_experience_content_visible,
      tokenize_journey_experience_content_observer,

      tokenize_journey_experience_button,
      is_tokenize_journey_experience_button_visible,
      tokenize_journey_experience_button_observer,

      tokenize_journey_blockchain_header,
      is_tokenize_journey_blockchain_header_visible,
      tokenize_journey_blockchain_header_observer,

      tokenize_journey_blockchain_content,
      is_tokenize_journey_blockchain_content_visible,
      tokenize_journey_blockchain_content_observer,

      tokenize_journey_blockchain_button,
      is_tokenize_journey_blockchain_button_visible,
      tokenize_journey_blockchain_button_observer,
    }
  },
  computed: {
    ...mapGetters(["countries"]),
  },
  methods: {
    ...mapActions(["setAlertpopUp"]),
  },
  mounted() {
    this.tokenize_journey_explore_header_observer.observe(this.tokenize_journey_explore_header)
    this.tokenize_journey_explore_content_observer.observe(this.tokenize_journey_explore_content)
    this.tokenize_journey_explore_button_observer.observe(this.tokenize_journey_explore_button)
    this.tokenize_journey_advantage_header_observer.observe(this.tokenize_journey_advantage_header)
    this.tokenize_journey_advantage_content_observer.observe(this.tokenize_journey_advantage_content)
    this.tokenize_journey_advantage_button_observer.observe(this.tokenize_journey_advantage_button)
    this.tokenize_journey_experience_header_observer.observe(this.tokenize_journey_experience_header)
    this.tokenize_journey_experience_content_observer.observe(this.tokenize_journey_experience_content)
    this.tokenize_journey_experience_button_observer.observe(this.tokenize_journey_experience_button)
    this.tokenize_journey_blockchain_header_observer.observe(this.tokenize_journey_blockchain_header)
    this.tokenize_journey_blockchain_content_observer.observe(this.tokenize_journey_blockchain_content)
    this.tokenize_journey_blockchain_button_observer.observe(this.tokenize_journey_blockchain_button)
    
  }
};
</script>

<style scoped>
[class*="loader"]:before {
  content: "Please wait...";
}
.tokenize-journey {
  background: #fff;
  width: 100%;
  padding: 100px 200px 100px;
  overflow: hidden;
}

.tokenize-journey h4 {
  font-family: var(--mainFontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 60px;
  color: #181818;
}

.tokenize-journey p {
  font-family: var(--mainFontFamily);
  font-style: normal;
  font-size: 16px;
  line-height: 33px;
  text-align: left;
  color: #000000;
}

.tokenize-journey ul li {
  line-height: 40px;
   color: #000000;
}
.token_button {
  margin-top: 70px;
}

.tokenize-journey button {
  border: none;
  background: var(--mainGreenColor);
  border-radius: 5px;
  padding: 11px 40px;
  color: #fff;

  transition: all 400ms ease;
}

.tokenize-journey button:hover {
  background: #fff;
  color: var(--mainGreenColor);
  border: 2px solid var(--mainGreenColor);
}

@media screen and (max-width: 1200px) {
  .tokenize-journey {
    padding: 80px 100px 100px;
  }

  .tokenize-journey h4 {
    font-size: 30px;
  }

  .tokenize-journey p {
    font-size: 16px;
  }
}

@media screen and (max-width: 900px) {
  .tokenize-journey {
    padding: 80px 60px 100px;
  }

  .tokenize-journey h4 {
    font-size: 27px;
  }

  .tokenize-journey p {
    font-size: 15px;
  }
}

@media screen and (max-width: 785px) {
  .tokenize-journey {
    padding: 80px 35px 100px;
  }
  .tokenize-journey h4 {
    font-size: 25px;
  }

  .tokenize-journey p {
    font-size: 15px;
  }
}

@media screen and (max-width: 605px) {
  .tokenize-journey {
    padding: 40px 30px 80px;
  }
}

@media screen and (max-width: 550px) {
  .tokenize-journey {
    padding: 80px 19px 40px;
  }
  .tokenize-journey h4 {
    font-size: 22px;
    line-height: 37px;
  }

  .tokenize-journey p {
    font-size: 14px;
  }

  .token_button {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
</style>
